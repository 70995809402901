import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { forkJoin } from 'rxjs';
import { first, tap } from 'rxjs/operators';

import { DragType } from '../../../../../enums';
import { AppState } from '../../../../../reducers';
import { CustomFieldsAppliesTo } from '../../../../../reducers/orm/custom-fields/custom-fields.model';
import { CustomFieldsService } from '../../../../../reducers/orm/custom-fields/custom-fields.service';
import { OpenShiftModel } from '../../../../../reducers/orm/open-shift/open-shift.model';
import { OpenShiftService } from '../../../../../reducers/orm/open-shift/open-shift.service';
import { RequiredShiftFulfillmentModel } from '../../../../../reducers/orm/required-shift-fulfillment/required-shift-fulfillment.model';
import { getRequiredShiftFulfillment } from '../../../../../reducers/orm/required-shift-fulfillment/required-shift-fulfillment.selector';
import { RequiredShiftFulfillmentService } from '../../../../../reducers/orm/required-shift-fulfillment/required-shift-fulfillment.service';
import { RequiredShiftModel } from '../../../../../reducers/orm/required-shift/required-shift.model';
import { ScheduleModel } from '../../../../../reducers/orm/schedule/schedule.model';
import { ScheduleService } from '../../../../../reducers/orm/schedule/schedule.service';
import { ShiftModel } from '../../../../../reducers/orm/shift/shift.model';
import { DragDropData } from '../../../../../shared/drag-drop/models';
import { DropZoneData } from '../../../../../shared/drag-drop/models/drag-drop-data.model';
import { NDraggable } from '../interfaces';

@Injectable({ providedIn: 'root' })
export class ScheduleNOpenShiftActionService {
  public constructor(
    private scheduleService: ScheduleService,
    private store: Store<AppState>,
    private openShiftService: OpenShiftService,
    private requiredShiftFulfillmentService: RequiredShiftFulfillmentService,
    private router: Router,
    private customFieldsService: CustomFieldsService,
  ) {}

  public canDropOnOpenShiftDay(draggable: NDraggable, dropZoneData: DropZoneData) {
    let matchingDepartmentId;
    const source = draggable.shift as ScheduleModel | OpenShiftModel | RequiredShiftModel;
    const { departmentId, date, teamId } = dropZoneData;

    if (draggable.shift.hasOwnProperty('department_id')) {
      matchingDepartmentId = departmentId === source.department_id;
    } else if (draggable.shift.hasOwnProperty('Department')) {
      matchingDepartmentId = departmentId === source.Department.id;
    }

    if (!matchingDepartmentId) {
      return false;
    }

    if (
      draggable.type === DragType.OPEN_SHIFT &&
      source.date === date &&
      departmentId === source.department_id &&
      source.team_id === teamId
    ) {
      return false;
    }

    return [DragType.SCHEDULE, DragType.OPEN_SHIFT, DragType.REQUIRED_SHIFT, DragType.SHIFT].includes(draggable.type);
  }

  public handleDropForOpenShiftRow(dragDropData: DragDropData) {
    const draggable = dragDropData.dragData;
    switch (draggable.type) {
      case DragType.SCHEDULE: {
        return this.onScheduleDrop(dragDropData);
      }
      case DragType.SHIFT: {
        return this.onShiftDrop(dragDropData);
      }
      case DragType.OPEN_SHIFT: {
        return this.onOpenShiftDrop(dragDropData);
      }
      case DragType.REQUIRED_SHIFT: {
        return this.onRequiredShiftDrop(dragDropData);
      }
    }
  }

  private onScheduleDrop(dragDropData: DragDropData) {
    const draggable = dragDropData.dragData;
    const isCopying = dragDropData.dragData.isCopying;
    const dropZoneData = dragDropData.dropZoneData;

    const schedule: ScheduleModel = <ScheduleModel>draggable.shift;
    const openShift = {
      id: undefined,
      shift_id: schedule.shift_id,
      department_id: dropZoneData.departmentId,
      team_id: dropZoneData.teamId,
      Team: dropZoneData.teamId,
      TeamJoin: { id: dropZoneData.teamId },
      date: dropZoneData.date,
      starttime: schedule.starttime,
      endtime: schedule.endtime,
      hide_end_time: schedule.hide_end_time,
      break: schedule.break,
      description: schedule.description,
      recurring: false,
      repeat_until: null,
      interval: 1,
      mo: false,
      tu: false,
      we: false,
      th: false,
      fr: false,
      sa: false,
      su: false,
      custom_fields: schedule.custom_fields,
    };

    if (!isCopying) {
      return forkJoin([
        this.scheduleService.remove(
          schedule.occurrence_id,
          { notify: false },
          schedule.recurring ? 'occurrence' : 'original',
          {
            userId: schedule.user_id,
            teamId: schedule.team_id,
            departmentId: schedule.department_id,
          },
        ),
        this.openShiftService.add(openShift),
      ]);
    }

    return this.openShiftService.add(openShift);
  }

  private onShiftDrop(dragDropData: DragDropData) {
    const draggable = dragDropData.dragData;
    const dropZoneData = dragDropData.dropZoneData;
    const shift: ShiftModel = <ShiftModel>draggable.shift;
    const openShift = {
      ...shift,
      id: undefined,
      shift_id: shift.id,
      department_id: shift.id,
      team_id: dropZoneData.teamId,
      Team: dropZoneData.teamId,
      TeamJoin: { id: dropZoneData.teamId },
      date: dropZoneData.date,
      custom_fields: this.customFieldsService.filterObsoleteCustomFields(
        shift.custom_fields,
        CustomFieldsAppliesTo.SCHEDULE,
      ),
    };

    return this.openShiftService.add(openShift);
  }

  private onOpenShiftDrop(dragDropData: DragDropData) {
    const draggable = dragDropData.dragData;
    const isCopying = dragDropData.dragData.isCopying;
    const dropZoneData = dragDropData.dropZoneData;
    const shift: OpenShiftModel = <OpenShiftModel>draggable.shift;
    if (isCopying) {
      const openShift = {
        ...shift,
        department_id: dropZoneData.departmentId,
        team_id: dropZoneData.teamId,
        Team: dropZoneData.teamId,
        TeamJoin: { id: dropZoneData.teamId },
        date: dropZoneData.date,
        recurring: false,
      };

      return this.openShiftService.add(openShift);
    } else {
      const openShift = {
        id: shift.id,
        department_id: dropZoneData.departmentId,
        team_id: dropZoneData.teamId,
        Team: dropZoneData.teamId,
        TeamJoin: { id: shift.team_id },
        date: dropZoneData.date,
        custom_fields: shift.custom_fields,
      };

      const scope = shift.recurring ? 'occurrence' : 'original';

      return this.openShiftService.update(shift.occurrence_id, openShift, scope);
    }
  }

  private onRequiredShiftDrop(dragDropData: DragDropData) {
    const draggable = dragDropData.dragData;
    const source = <RequiredShiftModel>draggable.shift;
    const dropZoneData = dragDropData.dropZoneData;

    return this.store.select(getRequiredShiftFulfillment(source.occurrence_id)).pipe(
      first(),
      tap((fulfillment: RequiredShiftFulfillmentModel) => {
        const instances = fulfillment.required - fulfillment.actual > 0 ? fulfillment.required - fulfillment.actual : 1;
        const params = {
          instances,
          date: dropZoneData.date,
          department: source.department_id,
          team: dropZoneData?.teamId,
          starttime: source.starttime,
          endtime: source.endtime,
          description: source.description,
          break: source.break,
          showDate: false,
        };

        if (source.shift_id) {
          params['shift'] = source.shift_id;
        }

        this.customFieldsService.customFieldsState.next(source.custom_fields);

        this.router.navigate(['', { outlets: { modal: ['schedule-open-shift', params] } }]);
      }),
    );
  }

  public handleCreate(departmentId: string, teamId: string, date: string) {
    const params = {
      date,
      department: departmentId,
      team: teamId,
      showDate: false,
    };

    this.router.navigate(['', { outlets: { modal: ['schedule-open-shift', params] } }]);
  }
}
