import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';

import { DragType } from '../../../../../enums';
import { AppState } from '../../../../../reducers';
import { CustomFieldsAppliesTo } from '../../../../../reducers/orm/custom-fields/custom-fields.model';
import { CustomFieldsService } from '../../../../../reducers/orm/custom-fields/custom-fields.service';
import { OpenShiftModel } from '../../../../../reducers/orm/open-shift/open-shift.model';
import { OpenShiftService } from '../../../../../reducers/orm/open-shift/open-shift.service';
import { RequiredShiftModel } from '../../../../../reducers/orm/required-shift/required-shift.model';
import { RequiredShiftService } from '../../../../../reducers/orm/required-shift/required-shift.service';
import { ScheduleModel } from '../../../../../reducers/orm/schedule/schedule.model';
import { ScheduleService } from '../../../../../reducers/orm/schedule/schedule.service';
import { ShiftModel } from '../../../../../reducers/orm/shift/shift.model';
import { DragDropData } from '../../../../../shared/drag-drop/models';
import { DropZoneData } from '../../../../../shared/drag-drop/models/drag-drop-data.model';
import { NDraggable } from '../interfaces';

@Injectable({ providedIn: 'root' })
export class ScheduleNTeamActionService {
  public isDragging: BehaviorSubject<NDraggable> = new BehaviorSubject<NDraggable>(null);

  constructor(
    private scheduleService: ScheduleService,
    private store: Store<AppState>,
    private openShiftService: OpenShiftService,
    private requiredShiftService: RequiredShiftService,
    private router: Router,
    private customFieldsService: CustomFieldsService,
  ) {}

  public canDropOnTeamShiftDay(draggable: NDraggable, dropZoneData: DropZoneData) {
    let matchingDepartmentId;
    const source = draggable.shift as ScheduleModel | OpenShiftModel | RequiredShiftModel;
    const { departmentId, date, teamId } = dropZoneData;

    if (draggable.shift.hasOwnProperty('department_id')) {
      matchingDepartmentId = departmentId === source.department_id;
    } else if (draggable.shift.hasOwnProperty('Department')) {
      matchingDepartmentId = departmentId === source.Department.id;
    }

    if (!matchingDepartmentId) {
      return false;
    }

    if (
      draggable.type === DragType.SCHEDULE &&
      source.date === date &&
      departmentId === source.department_id &&
      source.team_id === teamId
    ) {
      return false;
    }

    return [DragType.SCHEDULE, DragType.OPEN_SHIFT, DragType.REQUIRED_SHIFT, DragType.SHIFT].includes(draggable.type);
  }

  public handleDropForTeamShiftRow(dragDropData: DragDropData) {
    const draggable = dragDropData.dragData;
    switch (draggable.type) {
      case DragType.SCHEDULE: {
        return this.onScheduleDrop(dragDropData);
      }
      case DragType.OPEN_SHIFT: {
        return this.onOpenShiftDrop(dragDropData);
      }
      case DragType.REQUIRED_SHIFT: {
        return this.onRequiredShiftDrop(dragDropData);
      }
      case DragType.SHIFT: {
        return this.onShiftDrop(dragDropData);
      }
    }
  }

  private onScheduleDrop(dragDropData: DragDropData) {
    const draggable = dragDropData.dragData.shift;
    const isCopying = dragDropData.dragData.isCopying;
    const dropZoneData = dragDropData.dropZoneData;

    if (isCopying) {
      const schedule = {
        ...draggable,
        department_id: draggable.department_id,
        team_id: dropZoneData.teamId,
        Team: draggable.team_id,
        TeamJoin: { id: dropZoneData.teamId },
        user_id: draggable.user_id,
        User: draggable.user_id,
        date: dropZoneData.date,
        recurring: false,
      };

      delete schedule.id;
      delete schedule.occurrence_id;

      return this.scheduleService.add(schedule);
    } else {
      const schedule = {
        id: draggable.id,
        department_id: draggable.department_id,
        team_id: dropZoneData.teamId,
        Team: draggable.team_id,
        TeamJoin: { id: dropZoneData.teamId },
        user_id: draggable.user_id,
        User: draggable.user_id,
        date: dropZoneData.date,
        custom_fields: draggable.custom_fields,
      };

      const scope = draggable.recurring ? 'occurrence' : 'original';

      return this.scheduleService.update(draggable.occurrence_id, schedule, scope);
    }
  }

  private onOpenShiftDrop(dragDropData: DragDropData) {
    const draggable = dragDropData.dragData;
    const dropZoneData: DropZoneData = dragDropData.dropZoneData;
    const shift: OpenShiftModel = <OpenShiftModel>draggable.shift;

    const params = {
      department_id: dropZoneData.departmentId,
      date: dropZoneData.date,
      showDate: false,
    };

    this.router.navigate(['', { outlets: { modal: ['schedule-open-shift', shift.occurrence_id, 'assign', params] } }]);
  }

  private onRequiredShiftDrop(dragDropData: DragDropData) {
    const draggable = dragDropData.dragData;
    const dropZoneData: DropZoneData = dragDropData.dropZoneData;
    const requiredShift: RequiredShiftModel = <RequiredShiftModel>draggable.shift;

    const params = {
      date: dropZoneData.date,
      department: dropZoneData.departmentId,
      team: dropZoneData.teamId,
      starttime: requiredShift.starttime,
      endtime: requiredShift.endtime,
      showDate: false,
      description: requiredShift.description,
      break: requiredShift.break,
    };

    if (requiredShift.shift_id) {
      params['shift'] = requiredShift.shift_id;
    }

    this.customFieldsService.customFieldsState.next(requiredShift.custom_fields);

    this.router.navigate(['', { outlets: { modal: ['schedule-shift', params] } }]);
  }

  public handleCreate(departmentId: string, teamId: string, userId: string, date: string) {
    const params = {
      date: date,
      department: departmentId,
      team: teamId,
      showDate: false,
    };

    if (userId) {
      params['employee'] = userId;
    }

    this.router.navigate(['', { outlets: { modal: ['schedule-shift', params] } }]);
  }

  private onShiftDrop(dragDropData: DragDropData) {
    const draggable = dragDropData.dragData;
    const dropZoneData: DropZoneData = dragDropData.dropZoneData;
    const shift: ShiftModel = <ShiftModel>draggable.shift;

    const params = {
      date: dropZoneData.date,
      department: dropZoneData.departmentId,
      team: dropZoneData.teamId,
      shift: shift.id,
      starttime: shift.starttime,
      endtime: shift.endtime,
      hide_end_time: shift.hide_end_time,
      break: shift.break,
      showDate: false,
      description: shift.description,
      custom_fields: this.customFieldsService.filterObsoleteCustomFields(
        shift.custom_fields,
        CustomFieldsAppliesTo.SCHEDULE,
      ),
    };

    this.router.navigate(['', { outlets: { modal: ['schedule-shift', params] } }]);
  }
}
