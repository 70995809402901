import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounceTime, fromEvent, Subscription } from 'rxjs';

import { DragDropConfig } from '../config';
import { DragDropData } from '../models';
import { DragDropService } from '../service';

/* tslint:disable directive-selector no-output-on-prefix */
@Directive({ selector: '[dnd-free-draggable]' })
export class FreeDraggableDirective implements OnInit {
  @Input()
  public dragData: any;

  public element: HTMLElement;
  public defaultCursor: string;

  private _dragEnabled = true;
  public get dragEnabled(): boolean {
    return this._dragEnabled;
  }

  @Input()
  public set dragEnabled(value: boolean) {
    this._dragEnabled = value;
    this.element.draggable = value;
  }

  @Output()
  public onDragStart: EventEmitter<DragDropData> = new EventEmitter();
  @Output()
  public onDragEnd: EventEmitter<DragDropData> = new EventEmitter<DragDropData>();
  @Output()
  public onDragSuccess: EventEmitter<any> = new EventEmitter<any>();

  private dataSubs = new Subscription();

  public constructor(
    public elementReference: ElementRef,
    private dragDropService: DragDropService,
    private config: DragDropConfig,
  ) {
    this.element = this.elementReference.nativeElement;
    this.defaultCursor = this.element.style.cursor;
  }

  public ngOnInit() {
    this.dataSubs.add(
      fromEvent(this.element, 'dragstart').subscribe((event: DragEvent) => {
        event.dataTransfer.setData('text', (<any>event.target).id);
        this.dragStartCallback(event);
      }),
    );

    this.dataSubs.add(
      fromEvent(this.element, 'drag')
        .pipe(debounceTime(100))
        .subscribe((event: DragEvent) => {
          this.dragDropService.dragEvent.next(event);
        }),
    );

    this.dataSubs.add(
      fromEvent(this.element, 'dragend').subscribe((event: DragEvent) => {
        this.dragEndCallback(event);
      }),
    );
  }

  public dragStartCallback(event: DragEvent): void {
    this.dragDropService.isDragged = true;
    this.dragDropService.dragData = this.dragData;
    this.dragDropService.onDragSuccessCallback = this.onDragSuccess;
    this.element.classList.add(this.config.onDragStartClass);
    this.onDragStart.emit({ dragData: this.dragData, mouseEvent: event });
  }

  public dragEndCallback(event: DragEvent): void {
    this.dragDropService.isDragged = false;
    this.dragDropService.dragData = null;
    this.dragDropService.onDragSuccessCallback = null;
    this.dragDropService.dragEvent.next(null);
    this.element.classList.remove(this.config.onDragStartClass);
    this.onDragEnd.emit({ dragData: this.dragData, mouseEvent: event });
  }
}
