import { Injectable } from '@angular/core';
import { TrackingService } from '@app/services/tracking.service';
import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import memoize from 'lodash-es/memoize';
import { BehaviorSubject, Observable, fromEvent as observableFromEvent, Subscription } from 'rxjs';
import { distinctUntilChanged, map, share, startWith, take, tap } from 'rxjs/operators';

import { DragType } from '../../../../enums';
import { AppState } from '../../../../reducers';
import { EmployeeModel } from '../../../../reducers/orm/employee/employee.model';
import { getEmployeeEntities } from '../../../../reducers/orm/employee/employee.service';
import { getArrayDifference } from '../../../../shared/array.helper';
import { DragDropData } from '../../../../shared/drag-drop/models';
import { ScheduleNEmployeeActionService } from './action-services/schedule-n-employee-action.service';
import { ScheduleNEventActionService } from './action-services/schedule-n-event-action.service';
import { ScheduleNOpenShiftActionService } from './action-services/schedule-n-open-shift-action.service';
import { ScheduleNRequiredShiftActionService } from './action-services/schedule-n-required-shift-action.service';
import { ScheduleNTeamActionService } from './action-services/schedule-n-team-action.service';
import { NDraggable } from './interfaces';

@Injectable({ providedIn: 'root' })
export class ScheduleNActionService {
  public isDragging: BehaviorSubject<NDraggable> = new BehaviorSubject<NDraggable>(null);
  public isCopying = false;

  public isCopyingSubject = new BehaviorSubject<boolean>(false);

  public isCopying$ = this.isCopyingSubject.pipe(
    distinctUntilChanged(),
    tap((isCopying) => {
      this.isCopying = isCopying;
    }),
    share(),
    startWith(false),
  );

  public isHoldingKey$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public keyDown$: Observable<boolean> = observableFromEvent(document, 'keydown').pipe(
    map((event: KeyboardEvent) => event.ctrlKey || event.metaKey || event.shiftKey),
    tap((isDown) => {
      this.isHoldingKey$.next(isDown);
    }),
    share(),
    startWith(false),
  );

  public keyUp$: Observable<boolean> = observableFromEvent(document, 'keyup').pipe(
    map((event: KeyboardEvent) => event.ctrlKey || event.metaKey || event.shiftKey),
    tap((isDown) => {
      this.isHoldingKey$.next(isDown);
    }),
    share(),
    startWith(false),
  );

  private allowDropForEmployeeRow = memoize((dragDropData: DragDropData) =>
    this.employeeActionService.canDropOnEmployeeDay(dragDropData.dragData, dragDropData.dropZoneData),
  );
  public hasSkillWarning = memoize((dragDropData: DragDropData) => {
    const employeeId = dragDropData.dropZoneData.employeeId;
    const employee = this.employees[employeeId];

    // No employee, no skills to generate a warning for.
    if (!employee) {
      return false;
    }

    const shift = dragDropData.dragData.shift;
    const shiftSkills = shift?.Skill || shift?.Shift?.Skill;
    if (!shiftSkills?.length) {
      return false;
    }

    const employeeSkills = employee.Skill;

    // There are shift skills but no employee skills.  Which means the employee can't possibly have the required skill.
    if (!employeeSkills) {
      return true;
    }

    // Check if the dragging shift has skills that the employee does not have.
    const diff = getArrayDifference(
      shiftSkills.map((x) => x.id),
      employeeSkills.map((x) => x.id),
    );

    return diff.length > 0;
  });

  public allowDropForTeamEmployeeRow = memoize((dragDropData: DragDropData) =>
    this.teamActionService.canDropOnTeamShiftDay(dragDropData.dragData, dragDropData.dropZoneData),
  );

  public allowDropForOpenShiftRow = memoize((dragDropData: DragDropData) =>
    this.openShiftActionService.canDropOnOpenShiftDay(dragDropData.dragData, dragDropData.dropZoneData),
  );

  public allowDropForRequiredShiftRow = memoize((dragDropData: DragDropData) =>
    this.requiredShiftActionService.canDropOnRequiredShiftDay(dragDropData.dragData, dragDropData.dropZoneData),
  );

  public allowDropForEventRow = memoize((dragDropData: DragDropData) =>
    this.eventActionService.canDrop(dragDropData.dragData, dragDropData.dropZoneData),
  );

  private dataSubs = new Subscription();
  private teamView: boolean;
  private employees: Dictionary<EmployeeModel>;
  public constructor(
    private employeeActionService: ScheduleNEmployeeActionService,
    private teamActionService: ScheduleNTeamActionService,
    private openShiftActionService: ScheduleNOpenShiftActionService,
    private requiredShiftActionService: ScheduleNRequiredShiftActionService,
    private eventActionService: ScheduleNEventActionService,
    private store: Store<AppState>,
    private trackingService: TrackingService,
  ) {
    this.dataSubs.add(this.isCopying$.subscribe());
    this.dataSubs.add(this.keyDown$.subscribe());
    this.dataSubs.add(this.keyUp$.subscribe());
    this.dataSubs.add(
      this.store.select(getEmployeeEntities).subscribe((employees: Dictionary<EmployeeModel>) => {
        this.employees = employees;
      }),
    );
  }

  public setTeamView(value: boolean) {
    this.teamView = value;
  }

  public handleDrop(dragDropData: DragDropData) {
    let apiRes$;
    switch (dragDropData.dropZoneData.dropZoneType) {
      case DragType.SCHEDULE: {
        if (this.teamView) {
          apiRes$ = this.teamActionService.handleDropForTeamShiftRow(dragDropData);
          break;
        }

        apiRes$ = this.employeeActionService.handleDropForEmployeeRow(dragDropData);
        break;
      }
      case DragType.OPEN_SHIFT: {
        apiRes$ = this.openShiftActionService.handleDropForOpenShiftRow(dragDropData);
        break;
      }
      case DragType.REQUIRED_SHIFT: {
        apiRes$ = this.requiredShiftActionService.handleDropForRequiredShiftRow(dragDropData);
        break;
      }
      case DragType.EVENT: {
        apiRes$ = this.eventActionService.handleDropForEventRow(dragDropData);
        break;
      }
    }

    if (apiRes$) {
      void apiRes$.pipe(take(1)).subscribe(() => {
        const eventName = dragDropData.dragData['isCopying'] ? 'Shift Copied' : 'Shift Moved';
        this.trackingService.event(eventName);
      });
    }
  }

  public allowDropForScheduleRow() {
    if (this.teamView) {
      return this.allowDropForTeamEmployeeRow;
    }
    return this.allowDropForEmployeeRow;
  }
}
