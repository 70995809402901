import { NgClass, NgForOf, NgIf, NgStyle } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { PlanType } from '@app/+authenticated/+reports/shared/subscriptions/subscription.model';
import { ScheduleBaseItemComponent } from '@app/+authenticated/+schedule/employee/schedule-employee-n-period/schedule-items/base/schedule-base-item.component';
import { NPeriodService } from '@app/+authenticated/+schedule/shared/schedule-actions/n-period.service';
import { ScheduleNActionService } from '@app/+authenticated/+schedule/shared/schedule-actions/schedule-n-action.service';
import { IconComponent as OldIconComponent } from '@app/+authenticated/shared/icon.component';
import { DragType } from '@app/enums';
import { TimePipe } from '@app/pipes/time.pipe';
import { AppState } from '@app/reducers';
import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { DepartmentModel } from '@reducers/orm/department/department.model';
import { getDepartment } from '@reducers/orm/department/department.service';
import { ComplianceViolation } from '@reducers/orm/schedule-compliance/schedule-compliance.model';
import { getScheduleViolations } from '@reducers/orm/schedule-compliance/schedule-compliance.selectors';
import { ConflictTopic, ScheduleConflictModel } from '@reducers/orm/schedule-conflict/schedule-conflict.model';
import { getScheduleConflict } from '@reducers/orm/schedule-conflict/schedule-conflict.selectors';
import { ScheduleModel } from '@reducers/orm/schedule/schedule.model';
import { ScheduleService } from '@reducers/orm/schedule/schedule.service';
import { ShiftModel } from '@reducers/orm/shift/shift.model';
import { getShift } from '@reducers/orm/shift/shift.service';
import { TeamModel } from '@reducers/orm/team/team.model';
import { getTeam } from '@reducers/orm/team/team.service';
import { IconComponent } from '@sb/ui';
import { PeriodType } from '@shared/interfaces';
import { SbSpinnerComponent } from '@shared/sb-spinner.component';
import { SubscriptionPlanDirective } from '@shared/subscription-plan/subscription-plan.directive';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'schedule-shift-template',
  templateUrl: './schedule-shift-template.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TimePipe,
    NgIf,
    NgStyle,
    SubscriptionPlanDirective,
    OldIconComponent,
    IconComponent,
    SbSpinnerComponent,
    TranslateModule,
    NgForOf,
    NgClass,
    ScheduleBaseItemComponent,
  ],
})
export class ScheduleShiftTemplateComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public schedule: ScheduleModel;
  @Input()
  public team: TeamModel;
  @Input()
  public overrideShift?: ShiftModel;
  @Input()
  public overridePeriodType?: PeriodType;
  @Input()
  public departmentId: string;
  @Input()
  public mode: 'employee' | 'team' = 'employee';
  @Input()
  public disableHoverButtons: boolean;

  public isDisabled: boolean;

  public shift: ShiftModel;
  public violation: ComplianceViolation;

  public DragType = DragType;
  public planType = PlanType;
  public ConflictTopic = ConflictTopic;

  private dataSubs = new Subscription();
  private scheduleChanged = new BehaviorSubject<string>(null);
  public conflicts: Dictionary<ScheduleConflictModel[]> = null;

  public periodType: PeriodType = 'month';

  public scheduledTeam: TeamModel;
  public scheduledDepartment: DepartmentModel;

  public constructor(
    private store: Store<AppState>,
    private cd: ChangeDetectorRef,
    private router: Router,
    private zone: NgZone,
    private scheduleActionService: ScheduleNActionService,
    private periodService: NPeriodService,
    private scheduleService: ScheduleService,
  ) {}

  public ngOnInit(): void {
    this.periodType = this.overridePeriodType ?? this.periodService.getPeriod().periodType;
    this.isDisabled = this.checkDisabledState();

    this.dataSubs.add(
      this.scheduleChanged
        .pipe(
          distinctUntilChanged(),
          switchMap((shiftId) =>
            combineLatest([
              this.store.select(getShift(shiftId)),
              this.store.select(getTeam(this.schedule.team_id)),
              this.store.select(getDepartment(this.schedule.department_id)),
            ]),
          ),
        )
        .subscribe(([shift, team, department]) => {
          this.shift = this.overrideShift ?? shift;
          this.scheduledTeam = team;
          this.scheduledDepartment = department;
          this.cd.detectChanges();
        }),
    );

    this.dataSubs.add(this.getViolations());
    this.dataSubs.add(this.getConflicts());
  }

  private getViolations() {
    return this.store
      .select(getScheduleViolations(parseInt(this.schedule.user_id, 10), this.schedule.occurrence_id))
      .subscribe((violation: ComplianceViolation) => {
        this.violation = violation;
        this.cd.detectChanges();
      });
  }

  private getConflicts() {
    return this.store
      .select(getScheduleConflict(this.schedule.user_id, this.schedule.occurrence_id))
      .subscribe((conflicts: Dictionary<ScheduleConflictModel[]>) => {
        this.conflicts = conflicts;
        this.cd.detectChanges();
      });
  }

  public ngOnChanges(): void {
    if (!this.schedule || !this.schedule.shift_id) {
      return;
    }
    this.scheduleChanged.next(this.schedule?.shift_id);
    this.cd.detectChanges();
  }

  public ngOnDestroy(): void {
    this.dataSubs.unsubscribe();
  }

  private checkDisabledState() {
    if (!this.team || !this.schedule) {
      return false;
    }

    return (
      (this.schedule.team_id !== this.team.id && this.schedule.loaned) ||
      this.schedule.team_id !== this.team.id ||
      this.schedule.department_id !== this.departmentId
    );
  }

  public deleteSchedule() {
    this.zone.run(() => {
      if (this.scheduleActionService.isHoldingKey$.value) {
        this.scheduleService
          .remove(this.schedule.occurrence_id, { notify: false }, this.schedule.recurring ? 'occurrence' : 'original', {
            userId: this.schedule.user_id,
            teamId: this.schedule.team_id,
            departmentId: this.schedule.department_id,
          })
          .subscribe();
        return;
      }

      this.router.navigate(['', { outlets: { modal: ['schedule-shift', this.schedule.occurrence_id, 'delete'] } }]);
    });
  }
}
