import { ChangeDetectorRef, Directive, ElementRef, EventEmitter, Input, NgZone, Output } from '@angular/core';

import { ScheduleNActionService } from '../../../+authenticated/+schedule/shared/schedule-actions/schedule-n-action.service';
import { DragDropConfig } from '../config';
import { DragDropData } from '../models';
import { DragDropService } from '../service';
import { isPresent } from '../util';
import { AbstractDirective } from './abstract';

/* tslint:disable directive-selector no-output-on-prefix */
@Directive({ selector: '[dnd-droppable]' })
export class DroppableDirective extends AbstractDirective {
  @Output()
  onDropSuccess: EventEmitter<DragDropData> = new EventEmitter<DragDropData>();
  @Output()
  onDragEnter: EventEmitter<DragDropData> = new EventEmitter<DragDropData>();
  @Output()
  onDragOver: EventEmitter<DragDropData> = new EventEmitter<DragDropData>();
  @Output()
  onDragLeave: EventEmitter<DragDropData> = new EventEmitter<DragDropData>();

  public hasDragActions: boolean;

  public constructor(
    elementReference: ElementRef,
    dragDropService: DragDropService,
    config: DragDropConfig,
    cdr: ChangeDetectorRef,
    zone: NgZone,
    scheduleActionService: ScheduleNActionService,
  ) {
    super(elementReference, dragDropService, config, cdr, zone, scheduleActionService);

    this.dropEnabled = true;
  }

  public override dragEnterCallback(event: MouseEvent): void {
    if (this.dragDropService.isDragged) {
      this.element.classList.add(this.config.onDragEnterClass);
      this.onDragEnter.emit({ dragData: this.dragDropService.dragData, mouseEvent: event });
    }
  }

  public override dragOverCallback(event: MouseEvent): void {
    if (this.dragDropService.isDragged) {
      this.element.classList.add(this.config.onDragOverClass);
      this.onDragOver.emit({ dragData: this.dragDropService.dragData, mouseEvent: event });
    }
  }

  public override dragLeaveCallback(event: MouseEvent): void {
    if (this.dragDropService.isDragged) {
      this.onDragLeave.emit({ dragData: this.dragDropService.dragData, mouseEvent: event });
    }
  }

  public override dropCallback(event: MouseEvent): void {
    const dataTransfer = (event as any).dataTransfer;

    if (this.dragDropService.isDragged || (isPresent(dataTransfer) && isPresent(dataTransfer.files))) {
      this.onDropSuccess.emit({ dragData: this.dragDropService.dragData, mouseEvent: event });

      if (isPresent(this.dragDropService.onDragSuccessCallback)) {
        this.dragDropService.onDragSuccessCallback.emit({ dragData: this.dragDropService.dragData, mouseEvent: event });
      }

      this.element.classList.remove(this.config.onDragOverClass);
      this.element.classList.remove(this.config.onDragEnterClass);
    }
  }
}
