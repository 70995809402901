import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import omit from 'lodash-es/omit';

import { DragType } from '../../../../../enums';
import { AppState } from '../../../../../reducers';
import { OpenShiftModel } from '../../../../../reducers/orm/open-shift/open-shift.model';
import { OpenShiftService } from '../../../../../reducers/orm/open-shift/open-shift.service';
import { RequiredShiftModel } from '../../../../../reducers/orm/required-shift/required-shift.model';
import { RequiredShiftService } from '../../../../../reducers/orm/required-shift/required-shift.service';
import { ScheduleModel } from '../../../../../reducers/orm/schedule/schedule.model';
import { ScheduleService } from '../../../../../reducers/orm/schedule/schedule.service';
import { DragDropData } from '../../../../../shared/drag-drop/models';
import { DropZoneData } from '../../../../../shared/drag-drop/models/drag-drop-data.model';
import { NDraggable } from '../interfaces';

@Injectable({ providedIn: 'root' })
export class ScheduleNRequiredShiftActionService {
  public constructor(
    private scheduleService: ScheduleService,
    private store: Store<AppState>,
    private openShiftService: OpenShiftService,
    private requiredShiftService: RequiredShiftService,
    private router: Router,
  ) {}

  public canDropOnRequiredShiftDay(draggable: NDraggable, dropZoneData: DropZoneData) {
    let matchingDepartmentId;
    const source = draggable.shift as ScheduleModel | OpenShiftModel | RequiredShiftModel;
    const { departmentId, date, teamId } = dropZoneData;

    if (draggable.shift.hasOwnProperty('department_id')) {
      matchingDepartmentId = departmentId === source.department_id;
    }

    if (draggable.shift.hasOwnProperty('Department')) {
      matchingDepartmentId = departmentId === source.Department.id;
    }

    if (!matchingDepartmentId) {
      return false;
    }

    if (
      draggable.type === DragType.REQUIRED_SHIFT &&
      source.date === date &&
      departmentId === source.department_id &&
      source.team_id === teamId
    ) {
      return false;
    }

    return [DragType.SCHEDULE, DragType.OPEN_SHIFT, DragType.REQUIRED_SHIFT, DragType.SHIFT].includes(draggable.type);
  }

  public handleDropForRequiredShiftRow(dragDropData: DragDropData) {
    const draggable = dragDropData.dragData;

    switch (draggable.type) {
      case DragType.SCHEDULE: {
        return this.onScheduleDrop(dragDropData);
      }
      case DragType.SHIFT: {
        return this.onShiftDrop(dragDropData);
      }
      case DragType.OPEN_SHIFT: {
        return this.onOpenShiftDrop(dragDropData);
      }
      case DragType.REQUIRED_SHIFT: {
        return this.onRequiredShiftDrop(dragDropData);
      }
    }
  }

  private onScheduleDrop(dragDropData: DragDropData) {
    const draggable = dragDropData.dragData;
    const dropZoneData: DropZoneData = dragDropData.dropZoneData;
    const schedule: ScheduleModel = <ScheduleModel>draggable.shift;
    const requiredShift = {
      id: undefined,
      shift_id: schedule.shift_id,
      department_id: dropZoneData.departmentId,
      team_id: dropZoneData.teamId,
      // Team: team.id,
      // TeamJoin: { id: team.id, name: team.name },
      date: dropZoneData.date,
      starttime: schedule.starttime,
      endtime: schedule.endtime,
      hide_end_time: schedule.hide_end_time,
      break: schedule.break,
      recurring: false,
      repeat_until: null,
      interval: 1,
      description: schedule.description,
      mo: false,
      tu: false,
      we: false,
      th: false,
      fr: false,
      sa: false,
      su: false,

      custom_fields: schedule?.custom_fields,
    };

    return this.requiredShiftService.add(requiredShift);
  }

  private onShiftDrop(dragDropData: DragDropData) {
    const draggable = dragDropData.dragData;
    const dropZoneData: DropZoneData = dragDropData.dropZoneData;

    const requiredShift = {
      ...draggable.shift,
      id: undefined,
      department_id: dropZoneData.departmentId,
      team_id: dropZoneData.teamId,
      Team: dropZoneData.teamId,
      date: dropZoneData.date,
      recurring: false,
      shift_id: draggable.shift.id,
    };

    return this.requiredShiftService.add(requiredShift);
  }

  private onOpenShiftDrop(dragDropData: DragDropData) {
    const draggable = dragDropData.dragData;
    const dropZoneData: DropZoneData = dragDropData.dropZoneData;
    const source = <OpenShiftModel>draggable.shift;
    const requiredShift = {
      ...source,
      id: undefined,
      shift_id: source.shift_id,
      department_id: dropZoneData.departmentId,
      team_id: dropZoneData.teamId ?? null,
      Team: dropZoneData.teamId ?? null,
      // TeamJoin: teamId ? { id: t, name: team?.name } : {},
      date: dropZoneData.date,
    };

    return this.requiredShiftService.add(requiredShift);
  }

  private onRequiredShiftDrop(dragDropData: DragDropData) {
    const draggable = dragDropData.dragData;
    const isCopying = dragDropData.dragData.isCopying;
    const dropZoneData: DropZoneData = dragDropData.dropZoneData;
    const teamId = dropZoneData.teamId ?? null;

    const source = <RequiredShiftModel>draggable.shift;
    if (isCopying) {
      const requiredShift = {
        ...omit(source, ['id', 'occurrence_id', 'repeat_until', 'mo', 'tu', 'we', 'th', 'fr', 'sa', 'su']),
        department_id: dropZoneData.departmentId,
        team_id: teamId,
        TeamJoin: { id: source.team_id },
        date: dropZoneData.date,
        recurring: false,
      };

      return this.requiredShiftService.add(requiredShift);
    } else {
      const requiredShift = {
        id: source.id,
        department_id: dropZoneData.departmentId,
        team_id: teamId,
        TeamJoin: { id: source.team_id },
        date: dropZoneData.date,
      };

      const scope = source.recurring ? 'occurrence' : 'original';

      return this.requiredShiftService.update(source.occurrence_id, requiredShift, scope);
    }
  }

  public handleCreate(date: string, departmentId: string, teamId?: string) {
    const params = {
      date,
      department: departmentId,
      team: teamId,
      showDate: false,
    };

    this.router.navigate(['', { outlets: { modal: ['schedule-required-shift', params] } }]);
  }
}
