import { EventEmitter, Injectable } from '@angular/core';
import { DragDropData } from '../../models';
import { DragDropConfig } from '../../config';
import { DropZoneData } from '../../models/drag-drop-data.model';
import { DragType, DropZoneType } from '../../../../enums';
import { ScheduleNActionService } from '../../../../+authenticated/+schedule/shared/schedule-actions/schedule-n-action.service';
import { Subject } from 'rxjs';

export const copyZoneSupport = {
  [DragType.SHIFT]: [DropZoneType.SCHEDULE, DropZoneType.OPEN_SHIFT, DropZoneType.REQUIRED_SHIFT],
  [DragType.EVENT]: [DropZoneType.EVENT],
  [DragType.SCHEDULE]: [DropZoneType.SCHEDULE, DropZoneType.OPEN_SHIFT, DropZoneType.REQUIRED_SHIFT],
  [DragType.OPEN_SHIFT]: [DropZoneType.OPEN_SHIFT, DropZoneType.REQUIRED_SHIFT],
  [DragType.REQUIRED_SHIFT]: [DropZoneType.REQUIRED_SHIFT, DropZoneType.OPEN_SHIFT, DropZoneType.SCHEDULE],
};

export const moveZoneSupport = {
  [DragType.SHIFT]: [],
  [DragType.EVENT]: [],
  [DragType.SCHEDULE]: [DropZoneType.SCHEDULE, DropZoneType.OPEN_SHIFT],
  [DragType.OPEN_SHIFT]: [DropZoneType.SCHEDULE, DropZoneType.OPEN_SHIFT],
  [DragType.REQUIRED_SHIFT]: [DropZoneType.REQUIRED_SHIFT],
};

@Injectable({ providedIn: 'root' })
export class DragDropService {
  get lastEnterElement() {
    return this._lastEnterElement;
  }

  set lastEnterElement(value) {
    this._lastEnterElement = value;
    if (this._lastEnterElement) {
      const hasCopyZone = this.hasCopyZone();
      const hasMoveZone = this.hasMoveZone();

      if (!hasCopyZone) {
        this._lastEnterElement.children[0].classList.add(this.config.hiddenDropZone);
      }

      if (!hasMoveZone) {
        this._lastEnterElement.children[1].classList.add(this.config.hiddenDropZone);
      }

      if (!hasCopyZone || !hasMoveZone) {
        return;
      }

      this._lastEnterElement.children[0].classList.remove(this.config.hiddenDropZone);
      this._lastEnterElement.children[1].classList.remove(this.config.hiddenDropZone);
    }
  }

  allowedDropZones: string[] = [];
  onDragSuccessCallback: EventEmitter<DragDropData>;
  dragData: any;
  private _lastEnterElement;

  public isDragging: EventEmitter<boolean> = new EventEmitter<boolean>();
  public dragEvent = new Subject<DragEvent>();
  private _isDragged: boolean;

  constructor(private config: DragDropConfig, private scheduleActionService: ScheduleNActionService) {}

  get isDragged() {
    return this._isDragged;
  }

  set isDragged(isDrag: boolean) {
    if (!isDrag) {
      this.resetLastElement();
      this.lastEnterElement = null;
      this.scheduleActionService.isCopyingSubject.next(false);
      this.scheduleActionService.isDragging.next(null);
    }
    this._isDragged = isDrag;
    this.isDragging.emit(isDrag);
  }

  resetLastElement(includeSelf: boolean = true) {
    if (!this.lastEnterElement) {
      return;
    }

    if (includeSelf) {
      this.lastEnterElement.classList.remove(this.config.onDropEntered);
      this.lastEnterElement.classList.remove(this.config.onDragDeniedClass);
    }

    for (let i = 0; i < this.lastEnterElement.children.length; i++) {
      this.lastEnterElement.children[i].classList.remove(this.config.onDropEntered);
      this.lastEnterElement.children[i].classList.remove(this.config.hiddenDropZone);
    }
  }

  hasCopyZone(): boolean {
    const dragType = this.dragData.type;
    const dropZoneData = this.getDataSet();

    return copyZoneSupport[dragType]?.includes(dropZoneData.dropZoneType);
  }

  hasMoveZone(): boolean {
    const dragType = this.dragData.type;
    const dropZoneData = this.getDataSet();

    return moveZoneSupport[dragType]?.includes(dropZoneData.dropZoneType);
  }
  hasSkillsWarning(): boolean {
    const dropZoneData = this.getDataSet();
    if (!dropZoneData) {
      return false;
    }

    const dragDropData = {
      dragData: this.dragData,
      dropZoneData: dropZoneData,
    };
    return this.scheduleActionService.hasSkillWarning(dragDropData);
  }

  public getDataSet(): DropZoneData {
    if (!this.lastEnterElement) {
      return null;
    }

    const dataset = this.lastEnterElement.dataset;
    return {
      ...dataset,
      employeeEndDate: dataset.employeeEndDate ?? null,
      dropZoneType: parseInt(this.lastEnterElement.dataset.dropZoneType, 10),
    };
  }

  public isDropAllowed() {
    const dropZoneData = this.getDataSet();
    if (!dropZoneData) {
      return false;
    }

    const dragDropData = {
      dragData: this.dragData,
      dropZoneData: dropZoneData,
    };
    switch (dropZoneData.dropZoneType) {
      case DragType.SCHEDULE:
        return this.scheduleActionService.allowDropForScheduleRow()(dragDropData);
      case DragType.OPEN_SHIFT:
        return this.scheduleActionService.allowDropForOpenShiftRow(dragDropData);
      case DragType.REQUIRED_SHIFT:
        return this.scheduleActionService.allowDropForRequiredShiftRow(dragDropData);
      case DragType.EVENT:
        return this.scheduleActionService.allowDropForEventRow(dragDropData);
    }

    return false;
  }
}

export function dragDropServiceFactory(config: DragDropConfig, scheduleActionService): DragDropService {
  return new DragDropService(config, scheduleActionService);
}
