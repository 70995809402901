import { Injectable } from '@angular/core';

import { DataTransferEffect } from './data-transfer-effect';
import { DragImage } from './drag-image';
import { CursorType, DropZoneType } from '../../../enums';

export const getDropZoneType = (target: HTMLElement) => {
  if (!target) {
    return DropZoneType.NONE;
  }

  if (target.classList.contains(DropZoneType.COPY)) {
    return DropZoneType.COPY;
  }

  if (target.classList.contains(DropZoneType.MOVE)) {
    return DropZoneType.MOVE;
  }

  return DropZoneType.NONE;
};

export const getDropZoneParent = (event: MouseEvent) => {
  const target: any = event.target;
  const parent: HTMLElement = target.closest('.dropzone');

  return parent;
};

@Injectable({ providedIn: 'root' })
export class DragDropConfig {
  public onDragStartClass: string = 'dnd-drag-start';
  public onDragEnterClass: string = 'dnd-drag-enter';
  public onDragDeniedClass: string = 'dnd-drag-denied';
  public onDragWarningClass: string = 'dnd-drag-warning';
  public onDragOverClass: string = 'dnd-drag-over';
  public onSortableDragClass: string = 'dnd-sortable-drag';
  public hiddenDropZone = 'hidden-dropzone';

  public onDropEntered: string = 'dnd-drop-entered';

  public dragEffect: DataTransferEffect = DataTransferEffect.MOVE;
  public dropEffect: DataTransferEffect = DataTransferEffect.MOVE;
  public dragCursor: string = 'move';
  public dragImage: DragImage;
  public defaultCursor: CursorType = CursorType.POINTER;
}
