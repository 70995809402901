<schedule-base-item
  [scheduleItem]="schedule"
  [shift]="shift"
  [team]="team"
  [isDisabled]="isDisabled"
  [draggable]="{ type: DragType.SCHEDULE, shift: schedule }"
  [color]="shift?.color"
  [isConflicted]="!!conflicts || violation?.messages?.length > 0"
  (onDelete)="deleteSchedule()"
  [disableHoverButtons]="disableHoverButtons"
  [mode]="mode"
>
  <div class="item-tooltip-content" schedule-item-tooltip>
    <strong
      ><ng-container *ngIf="shift?.long_name; else external">{{ shift?.long_name }}&nbsp;</ng-container> </strong
    >{{ schedule.starttime | time }}
    <ng-container *ngIf="!schedule.hide_end_time">- {{ schedule.endtime | time }} </ng-container><br />
    <ng-container *ngIf="isDisabled && !!scheduledDepartment?.name && !!scheduledTeam?.name"
      ><strong>{{ scheduledDepartment.name }} | {{ scheduledTeam.name }}</strong
      ><br
    /></ng-container>
    <ng-container *ngIf="schedule.description">
      <strong>{{ 'Description' | translate }}:&nbsp;</strong
      ><span class="schedule-tooltip-description">{{ schedule.description }}</span></ng-container
    >

    <ng-container *ngIf="!!conflicts">
      <ng-container *subscriptionPlan="planType.BASIC">
        <ng-container *ngIf="conflicts[ConflictTopic.AVAILABILITY]">
          <br />
          <ng-container *ngFor="let conflict of conflicts[ConflictTopic.AVAILABILITY]">
            <span>{{ conflict.message }}</span>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="conflicts[ConflictTopic.TIME_OFF]">
          <br />
          <ng-container *ngFor="let conflict of conflicts[ConflictTopic.TIME_OFF]">
            <span>{{ conflict.message }}</span>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-container *subscriptionPlan="planType.EARLY_ADOPTER">
        <ng-container *ngIf="conflicts[ConflictTopic.SKILL]">
          <br /><br />
          <strong>{{ 'Missing skills' | translate }}:</strong>
          <ul>
            <ng-container *ngFor="let conflict of conflicts[ConflictTopic.SKILL]">
              <li>{{ conflict.message }}</li>
            </ng-container>
          </ul>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="conflicts[ConflictTopic.SCHEDULE]">
        <br />
        <ng-container *ngFor="let conflict of conflicts[ConflictTopic.SCHEDULE]">
          <span>{{ conflict.message }}</span>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="violation?.messages?.length > 0">
      <br /><br />
      <strong>{{ 'Violations' | translate }}:</strong>
      <ul>
        <ng-container *ngFor="let message of violation?.messages; let first = first">
          <li>{{ message }}</li>
        </ng-container>
      </ul>
    </ng-container>
  </div>

  <div schedule-item-content class="ml-3 flex h-full w-[calc(100%-10px)] justify-between pr-2">
    <div
      class="mt-[-1px] flex h-full flex-col justify-center truncate"
      [ngClass]="{
        italic: isDisabled,
        'text-word-mark-800': !isDisabled,
        'text-word-mark-400': isDisabled
      }"
    >
      <ng-container *ngIf="mode === 'employee'">
        <ng-container *ngIf="periodType === 'month'; else longName">
          <span class="overflow-hidden text-ellipsis text-[12px] font-bold">
            <ng-container *ngIf="shift?.name; else external">{{ shift?.name }}</ng-container>
          </span>
        </ng-container>
        <ng-template #longName>
          <span class="overflow-hidden text-ellipsis text-[12px] font-bold">
            <ng-container *ngIf="shift?.long_name; else external">{{ shift?.long_name }}</ng-container>
          </span>
        </ng-template>
        <span class="text-xs text-ellipsis overflow-hidden {{ isDisabled ? 'text-word-mark-400' : 'text-grey-600' }}"
          >{{ schedule.starttime | time }}
          <ng-container *ngIf="!schedule.hide_end_time">- {{ schedule.endtime | time }}</ng-container>
        </span>
      </ng-container>

      <ng-container *ngIf="mode === 'team'">
        <span class="overflow-hidden text-ellipsis text-xs font-bold"
          >{{ shift?.name }} | {{ schedule.starttime | time }}
          <ng-container *ngIf="!schedule.hide_end_time">- {{ schedule.endtime | time }}</ng-container>
        </span>
        <span class="text-xs text-ellipsis overflow-hidden {{ isDisabled ? 'text-word-mark-400' : 'text-grey-600' }}">{{
          schedule.employeeName
        }}</span>
      </ng-container>
    </div>

    <div class="sh-tl__static--icons">
      <ng-container *ngIf="shift?.is_task && !schedule.loading">
        <div class="mr-2 hidden items-center text-word-mark-800 last:mr-0 lg:flex">
          <icon class="sh-tl__btn-icon" svgIcon="clipboard"></icon>
        </div>
      </ng-container>

      <ng-container *ngIf="(!!conflicts || violation?.messages?.length > 0) && !schedule.loading">
        <div class="mr-2 hidden items-center text-error-500 last:mr-0 lg:flex">
          <sb-icon [name]="'exclamation-circle-solid'" [size]="'lg'"></sb-icon>
        </div>
      </ng-container>

      <ng-container *subscriptionPlan="planType.BASIC">
        <ng-container *ngIf="schedule.Exchange && !schedule.loading">
          <div class="mr-1 hidden items-center text-word-mark-800 last:mr-0 lg:flex">
            <icon class="sh-tl__btn-icon" svgIcon="change_shift"></icon>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="schedule.recurring && !schedule.Exchange && !schedule.loading">
        <div class="mr-1 hidden items-center text-word-mark-800 last:mr-0 lg:flex">
          <icon class="sh-tl__btn-icon" svgIcon="repeat"></icon>
        </div>
      </ng-container>
      <ng-container *ngIf="schedule.loading">
        <div class="mr-2 hidden items-center text-word-mark-800 last:mr-0 lg:flex">
          <sb-spinner class="small" [isWhite]="shift?.color_is_dark" [isBlack]="!shift?.color_is_dark"></sb-spinner>
        </div>
      </ng-container>
    </div>
  </div>
</schedule-base-item>

<!--When there is no shift information show external-->
<ng-template #external> {{ 'External' | translate }}&nbsp; </ng-template>
