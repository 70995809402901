import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { DragType } from '../../../../../enums';
import { CustomFieldsAppliesTo } from '../../../../../reducers/orm/custom-fields/custom-fields.model';
import { CustomFieldsService } from '../../../../../reducers/orm/custom-fields/custom-fields.service';
import { OpenShiftModel } from '../../../../../reducers/orm/open-shift/open-shift.model';
import { OpenShiftService } from '../../../../../reducers/orm/open-shift/open-shift.service';
import { RequiredShiftModel } from '../../../../../reducers/orm/required-shift/required-shift.model';
import { ScheduleModel } from '../../../../../reducers/orm/schedule/schedule.model';
import { ScheduleService } from '../../../../../reducers/orm/schedule/schedule.service';
import { ShiftModel } from '../../../../../reducers/orm/shift/shift.model';
import { shouldMoveLoaned } from '../../../../../reducers/store/schedule/schedule-n.helper';
import { DragDropData, DropZoneData } from '../../../../../shared/drag-drop/models/drag-drop-data.model';
import { canEmployeeBeScheduledOnDate } from '../../schedule-conflict.helper';
import { NDraggable } from '../interfaces';

@Injectable({ providedIn: 'root' })
export class ScheduleNEmployeeActionService {
  public isDragging: BehaviorSubject<NDraggable> = new BehaviorSubject<NDraggable>(null);

  public constructor(
    private scheduleService: ScheduleService,
    private openShiftService: OpenShiftService,
    private router: Router,
    private customFieldsService: CustomFieldsService,
  ) {}

  public canDropOnEmployeeDay(draggable: NDraggable, dropZoneData: DropZoneData) {
    let matchingDepartmentId;
    const source = draggable.shift as ScheduleModel | OpenShiftModel | RequiredShiftModel;
    const { departmentId, date, teamId, employeeId, employeeStartDate, employeeEndDate } = dropZoneData;

    if (Object.hasOwn(draggable.shift, 'department_id')) {
      matchingDepartmentId = departmentId === source.department_id;
    } else if (Object.hasOwn(draggable.shift, 'Department')) {
      matchingDepartmentId = departmentId === source.Department.id;
    }

    if (!matchingDepartmentId) {
      return false;
    }

    if (
      draggable.type === DragType.SCHEDULE &&
      source.date === date &&
      departmentId === source.department_id &&
      source.team_id === teamId &&
      source.user_id === employeeId
    ) {
      return false;
    }
    if (![DragType.SCHEDULE, DragType.OPEN_SHIFT, DragType.REQUIRED_SHIFT, DragType.SHIFT].includes(draggable.type)) {
      return false;
    }

    return canEmployeeBeScheduledOnDate({ startdate: employeeStartDate, enddate: employeeEndDate }, date, []);
  }

  public handleDropForEmployeeRow(dragDropData: DragDropData) {
    const draggable = dragDropData.dragData;
    switch (draggable.type) {
      case DragType.SCHEDULE: {
        return this.onScheduleDrop(dragDropData);
      }
      case DragType.OPEN_SHIFT: {
        return this.onOpenShiftDrop(dragDropData);
      }
      case DragType.REQUIRED_SHIFT: {
        return this.onRequiredShiftDrop(dragDropData);
      }
      case DragType.SHIFT: {
        return this.onShiftDrop(dragDropData);
      }
    }
  }

  private onScheduleDrop(dragDropData: DragDropData) {
    const draggable = dragDropData.dragData.shift;
    const isCopying = dragDropData.dragData.isCopying;
    const dropZoneData = dragDropData.dropZoneData;

    if (isCopying) {
      const schedule = {
        ...draggable,
        department_id: draggable.department_id,
        team_id: draggable.team_id,
        Team: draggable.team_id,
        TeamJoin: { id: dropZoneData.teamId },
        user_id: dropZoneData.employeeId,
        User: draggable.user_id,
        date: dropZoneData.date,
        recurring: false,
      };

      delete schedule.id;
      delete schedule.occurrence_id;

      return this.scheduleService.add(schedule);
    } else {
      const schedule = {
        id: draggable.id,
        department_id: draggable.department_id,
        team_id: draggable.team_id,
        Team: draggable.team_id,
        TeamJoin: { id: dropZoneData.teamId },
        user_id: dropZoneData.employeeId,
        User: draggable.user_id,
        date: dropZoneData.date,
        custom_fields: draggable.custom_fields,
      };

      if (shouldMoveLoaned(draggable, dropZoneData)) {
        schedule.Team = dropZoneData.teamId;
        schedule.team_id = dropZoneData.teamId;
      }

      const scope = draggable.recurring ? 'occurrence' : 'original';

      return this.scheduleService.update(draggable.occurrence_id, schedule, scope);
    }
  }

  private onOpenShiftDrop(dragDropData: DragDropData) {
    const draggable = dragDropData.dragData;
    const dropZoneData: DropZoneData = dragDropData.dropZoneData;
    const shift: OpenShiftModel = <OpenShiftModel>draggable.shift;

    const schedule = {
      ...shift,
      id: undefined,
      shift_id: shift.shift_id,
      department_id: shift.department_id,
      team_id: shift.team_id,
      Team: shift.team_id,
      TeamJoin: { id: dropZoneData.teamId },
      user_id: dropZoneData.employeeId,
      User: dropZoneData.employeeId,
      date: dropZoneData.date,
    };

    return this.openShiftService.assign(shift.occurrence_id, schedule);
  }

  private onRequiredShiftDrop(dragDropData: DragDropData) {
    const draggable = dragDropData.dragData;
    const dropZoneData: DropZoneData = dragDropData.dropZoneData;
    const requiredShift: RequiredShiftModel = <RequiredShiftModel>draggable.shift;

    if (!requiredShift.shift_id || requiredShift.time_settings !== 'exact') {
      const params = {
        date: dropZoneData.date,
        department: requiredShift.department_id,
        team: requiredShift?.team_id ?? dropZoneData.teamId,
        employee: dropZoneData.employeeId,
        showDate: false,
        description: requiredShift.description,
        break: requiredShift.break,
        starttime: requiredShift.starttime,
        endtime: requiredShift.endtime,
      };
      this.customFieldsService.customFieldsState.next(requiredShift?.custom_fields);

      if (requiredShift.shift_id) {
        params['shift'] = requiredShift.shift_id;
      }

      this.router.navigate(['', { outlets: { modal: ['schedule-shift', params] } }]);
      return;
    }

    const schedule = {
      ...requiredShift,
      department_id: requiredShift.department_id,
      team_id: requiredShift.team_id ?? dropZoneData.teamId,
      Team: dropZoneData.teamId,
      TeamJoin: { id: dropZoneData.teamId },
      user_id: dropZoneData.employeeId,
      User: dropZoneData.employeeId,
      date: dropZoneData.date,
      recurring: false,
      custom_fields: requiredShift?.custom_fields,
    };

    return this.scheduleService.add(schedule);
  }

  public handleCreate(departmentId: string, teamId: string, userId: string, date: string) {
    const params = {
      date: date,
      department: departmentId,
      team: teamId,
      showDate: false,
    };

    if (userId) {
      params['employee'] = userId;
    }

    this.router.navigate(['', { outlets: { modal: ['schedule-shift', params] } }]);
  }

  private onShiftDrop(dragDropData: DragDropData) {
    const draggable = dragDropData.dragData;
    const dropZoneData: DropZoneData = dragDropData.dropZoneData;
    const shift: ShiftModel = <ShiftModel>draggable.shift;

    const schedule = {
      ...shift,
      id: undefined,
      shift_id: shift.id,
      department_id: shift.department_id,
      team_id: dropZoneData.teamId,
      Team: dropZoneData.teamId,
      TeamJoin: { id: dropZoneData.teamId },
      user_id: dropZoneData.employeeId,
      User: dropZoneData.employeeId,
      date: dropZoneData.date,
      custom_fields: this.customFieldsService.filterObsoleteCustomFields(
        shift.custom_fields,
        CustomFieldsAppliesTo.SCHEDULE,
      ),
    };

    return this.scheduleService.add(schedule);
  }
}
