<ng-template #tp>
  <ng-content select="[schedule-item-tooltip]"></ng-content>
</ng-template>

<div
  [sbTooltip]="tp"
  [sbTooltipPlacement]="'top'"
  [sbTooltipDelay]="400"
  [sbTooltipDisabled]="isDragging"
  dnd-free-draggable
  [dragEnabled]="!isDisabled && scheduleItem.permissions?.canEdit"
  [dragData]="draggable"
  class="schedule-item group flex h-[34px] items-center rounded border-[2px]"
  [ngClass]="{
    'border-dashed border-grey-400': isDisabled,
    'border-solid': !isDisabled,
    'sh-tl__conflicts': isConflicted,
    'schedule-item-ghost': shouldDisplayGhostWhenDraggingButNotCopying || !isDraggingGracePeriod
  }"
  [ngStyle]="{
    backgroundColor: isDisabled ? 'white' : (color ?? '#2399e4' | lightenHex: { forceLuminosity: 97 }),
    borderColor: color ?? '#2399e4'
  }"
>
  <div class="relative left-0 top-0">
    <div
      class="absolute top-[-13px] ml-[2px] h-[26px] w-[6px] min-w-[6px] rounded-full"
      [ngStyle]="{
        backgroundColor: color ?? '#2399e4'
      }"
    ></div>
  </div>
  <div class="sh-tl__static w-full">
    <ng-content select="[schedule-item-content]"></ng-content>
    <ng-container *ngIf="!isDragging && !scheduleItem.loading && !disableHoverButtons">
      <div
        class="transition:opacity relative left-[-2px] top-[-28px] flex h-[26px] w-full opacity-0 duration-300 group-hover:opacity-100"
      >
        <ng-container *ngIf="scheduleItem.permissions?.canEdit">
          <div
            class="ml-[2px] flex w-[40px] cursor-pointer items-center justify-center overflow-hidden rounded-[2px] border-[2px] border-solid border-grey-300 bg-white py-[2px] first:grow hover:bg-grey-300"
            (click)="edit($event)"
          >
            <icon class="sh-tl__btn-icon" svgIcon="edit"></icon>
          </div>
        </ng-container>

        <ng-container *ngIf="!scheduleItem.permissions?.canEdit">
          <div
            class="ml-[2px] flex w-[40px] cursor-pointer items-center justify-center overflow-hidden rounded-[2px] border-[2px] border-solid border-grey-300 bg-white py-[2px] first:grow hover:bg-grey-300"
            (click)="view($event)"
          >
            <icon class="sh-tl__btn-icon" svgIcon="zoom"></icon>
          </div>
        </ng-container>

        <ng-container *ngIf="scheduleItem.permissions?.canDelete">
          <div
            class="ml-[2px] flex w-[40px] cursor-pointer items-center justify-center overflow-hidden rounded-[2px] border-[2px] border-solid border-grey-300 bg-white py-[2px] first:grow hover:bg-grey-300"
            (click)="delete($event)"
          >
            <icon class="sh-tl__btn-icon" svgIcon="delete"></icon>
          </div>
        </ng-container>

        <ng-container *ngIf="scheduleItem.permissions?.canCreate">
          <div
            (click)="create($event)"
            class="ml-[2px] flex w-[40px] cursor-pointer items-center justify-center overflow-hidden rounded-[2px] border-[2px] border-solid border-grey-300 bg-white py-[2px] first:grow hover:bg-grey-300"
          >
            <icon class="sh-tl__btn-icon" svgIcon="sum"></icon>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
