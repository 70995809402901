import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { DragType } from '../../../../../enums';
import { AppState } from '../../../../../reducers';
import { EventModel } from '../../../../../reducers/orm/event/event.model';
import { EventService } from '../../../../../reducers/orm/event/event.service';
import { DragDropData } from '../../../../../shared/drag-drop/models';
import { DropZoneData } from '../../../../../shared/drag-drop/models/drag-drop-data.model';
import { NDraggable } from '../interfaces';

@Injectable({ providedIn: 'root' })
export class ScheduleNEventActionService {
  public constructor(
    private store: Store<AppState>,
    private eventService: EventService,
    private router: Router,
  ) {}

  public canDrop(draggable: NDraggable, dropZoneData: DropZoneData): boolean {
    const { departmentId } = dropZoneData;
    return draggable.type === DragType.EVENT && departmentId === draggable.shift.department_id;
  }

  public handleDropForEventRow(dragDropData: DragDropData) {
    const draggable = dragDropData.dragData;
    switch (draggable.type) {
      case DragType.EVENT: {
        return this.onEventDrop(dragDropData);
      }
    }
  }

  public handleCreate(date: string, departmentId: string) {
    const params = {
      date,
      department: departmentId,
    };

    this.router.navigate(['', { outlets: { modal: ['event', params] } }]);
  }

  private onEventDrop(dragDropData: DragDropData) {
    const draggable = dragDropData.dragData;
    const isCopying = dragDropData.dragData.isCopying;
    const dropZoneData: DropZoneData = dragDropData.dropZoneData;
    const source = <EventModel>draggable.shift;

    // Are we dropping an event on itself?
    if (source.department_id === dropZoneData.departmentId && source.date === dropZoneData.date) {
      return;
    }

    if (isCopying) {
      const event = {
        ...source,
        department_id: dropZoneData.departmentId,
        date: dropZoneData.date,
      };

      delete event.id;

      return this.eventService.add(event);
    } else {
      const event = {
        id: source.id,
        department_id: dropZoneData.departmentId,
        date: dropZoneData.date,
      };

      return this.eventService.update(source.id, event);
    }
  }
}
