import { ModuleWithProviders, NgModule } from '@angular/core';

import { ScheduleNActionService } from '../../+authenticated/+schedule/shared/schedule-actions/schedule-n-action.service';
import { DragDropConfig } from './config';
import {
  DroppableDirective,
  FreeDraggableDirective,
  FreeDroppableDirective,
  SortableContainerDirective,
  SortableDirective,
} from './directives';
import {
  DragDropService,
  dragDropServiceFactory,
  DragDropSortableService,
  dragDropSortableServiceFactory,
} from './service';

export * from './config';
export * from './directives';
export * from './models';
export * from './service';

export const providers = [
  DragDropConfig,
  { provide: DragDropService, useFactory: dragDropServiceFactory, deps: [DragDropConfig, ScheduleNActionService] },
  { provide: DragDropSortableService, useFactory: dragDropSortableServiceFactory, deps: [DragDropConfig] },
];

@NgModule({
  declarations: [
    FreeDraggableDirective,
    FreeDroppableDirective,
    DroppableDirective,
    SortableContainerDirective,
    SortableDirective,
  ],
  exports: [
    FreeDraggableDirective,
    FreeDroppableDirective,
    DroppableDirective,
    SortableContainerDirective,
    SortableDirective,
  ],
})
export class SFDragDropModule {
  public static forRoot(): ModuleWithProviders<SFDragDropModule> {
    return { ngModule: SFDragDropModule, providers: providers };
  }
}
