import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

import { ScheduleNActionService } from '../../../+authenticated/+schedule/shared/schedule-actions/schedule-n-action.service';
import { DropZoneType } from '../../../enums';
import { DragDropConfig } from '../config';
import { getDropZoneParent, getDropZoneType } from '../config/drag-drop-config';
import { DragDropData } from '../models';
import { DragDropService } from '../service';
import { isPresent } from '../util';

@Directive({ selector: '[dnd-free-droppable]' })
export class FreeDroppableDirective implements OnInit, OnDestroy {
  @Output()
  public onDropSuccess: EventEmitter<DragDropData> = new EventEmitter<DragDropData>();
  @Output()
  public onDragEnter: EventEmitter<DragDropData> = new EventEmitter<DragDropData>();
  @Output()
  public onDragOver: EventEmitter<DragDropData> = new EventEmitter<DragDropData>();
  @Output()
  public onDragLeave: EventEmitter<DragDropData> = new EventEmitter<DragDropData>();

  private dataSubs = new Subscription();

  public constructor(
    private elementReference: ElementRef,
    private dragDropService: DragDropService,
    private scheduleActionService: ScheduleNActionService,
    private config: DragDropConfig,
  ) {}

  public ngOnInit() {
    this.dataSubs.add(
      fromEvent(this.elementReference.nativeElement, 'dragover').subscribe((event: DragEvent) => {
        event.preventDefault();
        this.dragOverCallback(event);

        if (isPresent(event.dataTransfer)) {
          event.dataTransfer.dropEffect = this.config.dropEffect.name;
        }

        return false;
      }),
    );

    this.dataSubs.add(
      fromEvent(this.elementReference.nativeElement, 'drop').subscribe((event: DragEvent) => {
        if (this.dragDropService.isDropAllowed()) {
          event.preventDefault();
          event.stopPropagation();

          this.dropCallback(event);
        }
        this.scheduleActionService.isDragging.next(null);
      }),
    );
  }

  public ngOnDestroy() {
    this.dataSubs.unsubscribe();
  }

  public dragOverCallback(event: DragEvent): void {
    if (!this.dragDropService.isDragged) {
      return;
    }

    let target: any = event.target;
    target = target.nodeName !== 'DIV' ? target.closest('div') : target;
    const dropZoneType = getDropZoneType(target);
    const parent = getDropZoneParent(event);

    if (dropZoneType === DropZoneType.NONE) {
      this.scheduleActionService.isCopyingSubject.next(false);
      return;
    }

    if (this.dragDropService.lastEnterElement !== parent) {
      this.dragDropService.resetLastElement();
    }

    this.dragDropService.resetLastElement(false);
    this.dragDropService.lastEnterElement = parent;

    if (!this.dragDropService.isDropAllowed()) {
      parent.classList.add(this.config.onDragDeniedClass);
      this.scheduleActionService.isCopyingSubject.next(false);
      return;
    }

    if (this.dragDropService.hasSkillsWarning()) {
      parent.classList.add(this.config.onDragWarningClass);
    } else {
      parent.classList.remove(this.config.onDragWarningClass);
    }

    parent.classList.add(this.config.onDropEntered);
    target.classList.add(this.config.onDropEntered);
    this.scheduleActionService.isCopyingSubject.next(dropZoneType === DropZoneType.COPY);

    this.onDragOver.emit({ dragData: this.dragDropService.dragData, mouseEvent: event });
  }

  public dropCallback(event: MouseEvent): void {
    const dataTransfer = (event as any).dataTransfer;
    let target: any = event.target;

    target = target.nodeName !== 'DIV' ? target.closest('div') : target;
    const dropZoneType = getDropZoneType(target);
    const parent = getDropZoneParent(event);

    const isCopying = dropZoneType === DropZoneType.COPY;
    if (dropZoneType === DropZoneType.NONE) {
      return;
    }

    if (!this.dragDropService.isDropAllowed()) {
      return;
    }

    this.dragDropService.lastEnterElement = parent;

    if (this.dragDropService.isDragged || (isPresent(dataTransfer) && isPresent(dataTransfer.files))) {
      this.onDropSuccess.emit({
        dragData: { ...this.dragDropService.dragData, isCopying },
        mouseEvent: event,
        dropZoneData: this.dragDropService.getDataSet(),
      });

      if (isPresent(this.dragDropService.onDragSuccessCallback)) {
        this.dragDropService.onDragSuccessCallback.emit({ dragData: this.dragDropService.dragData, mouseEvent: event });
      }
    }
  }
}
