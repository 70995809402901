import { ChangeDetectorRef, Directive, ElementRef, Input, NgZone, OnDestroy, ViewRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { ScheduleNActionService } from '../../../../+authenticated/+schedule/shared/schedule-actions/schedule-n-action.service';
import { CursorType, DragType } from '../../../../enums';
import { DragDropConfig, DragImage } from '../../config';
import { DragDropService } from '../../service';
import { isPresent } from '../../util';

@Directive()
export abstract class AbstractDirective implements OnDestroy {
  public element: HTMLElement;
  private _dragHandle: HTMLElement;
  public dragHelper: HTMLElement;
  public defaultCursor: string;

  public target: EventTarget;

  private _dragEnabled = true;

  @Input()
  public dropEnabled = false;

  @Input()
  public effectAllowed: string;

  @Input()
  public effectCursor: string;

  @Input()
  public dropZones: string[] = [];

  @Input()
  public dragImage: string | DragImage | Function;

  @Input()
  public cloneItem = false;

  private dataSubs = new Subscription();

  public constructor(
    public elementReference: ElementRef,
    public dragDropService: DragDropService,
    public config: DragDropConfig,
    private cdr: ChangeDetectorRef,
    public zone: NgZone,
    public scheduleActionService: ScheduleNActionService = null,
  ) {
    this.defaultCursor = this.config.defaultCursor;
    this.element = elementReference.nativeElement;
    this.element.style.cursor = this.defaultCursor;

    this.zone.runOutsideAngular(() => {
      this.element.ondragenter = (event: Event) => this.dragEnter(event);
      this.element.ondragleave = (event: Event) => this.dragLeave(event);
      this.element.ondrop = (event: Event) => this.drop(event);
      this.element.ondragover = (event: DragEvent) => {
        this.dragOver(event);

        if (isPresent(event.dataTransfer)) {
          event.dataTransfer.dropEffect = this.config.dropEffect.name;
        }

        return false;
      };
      this.element.ondragstart = (event: DragEvent) => {
        if (isPresent(this.dragHandle)) {
          if (!this.dragHandle.contains(this.target as Element)) {
            event.preventDefault();
            return;
          }
        }
        /**
         * Firefox and perhaps IE? requires data to be set on a drag start
         */
        event.dataTransfer.setData('text', (<any>event.target).id);

        this.dragStart(event);
      };

      this.element.ondragend = (event: Event) => {
        if (this.element.parentElement && this.dragHelper) {
          this.element.parentElement.removeChild(this.dragHelper);
        }
        if (isPresent(this.dragEndCallback(event))) {
          this.dragEnd(event);
        }
        // Restore style of dragged element
        const cursorElem = this._dragHandle ? this._dragHandle : this.element;
        cursorElem.style.cursor = this.defaultCursor;
      };
    });

    // Register drag events
    this.element.onmousedown = (event: MouseEvent) => {
      if (document.getSelection() && typeof document.getSelection().empty === 'function') {
        document.getSelection().empty();
      } else if (window.getSelection()) {
        window.getSelection().removeAllRanges();
      }
      this.target = event.target;
    };
  }

  public get dragEnabled(): boolean {
    return this._dragEnabled;
  }

  @Input()
  public set dragEnabled(value: boolean) {
    this._dragEnabled = value;
    this.element.draggable = value;
  }

  public get dragHandle(): HTMLElement {
    return this._dragHandle;
  }

  public set dragHandle(value: HTMLElement) {
    this._dragHandle = value;
  }

  /**
   * Run change detection manually to fix an issue in Safari.
   *
   * @memberof AbstractDirective
   */
  public detectChanges() {
    setTimeout(() => {
      if (this.cdr && !(this.cdr as ViewRef).destroyed) {
        this.cdr.detectChanges();
      }
    }, 250);
  }

  private dragEnter(event: Event): void {
    if (this.isDropAllowed()) {
      this.dragEnterCallback(event);
    } else {
      this.element.classList.add(this.config.onDragDeniedClass);
    }
  }

  private changeCursorType(cursorType: CursorType) {
    if (this.element.style.cursor !== cursorType) {
      this.element.style.cursor = cursorType;
    }
  }

  private dragOver(event: Event): void {
    if (isPresent(event.preventDefault)) {
      event.preventDefault();
    }

    this.dragOverCallback(event);
  }

  private dragLeave(event: Event): void {
    if (this.isDropAllowed()) {
      this.dragLeaveCallback(event);
    } else {
      this.element.classList.remove(this.config.onDragDeniedClass);
    }
  }

  private drop(event: Event): void {
    if (this.isDropAllowed()) {
      this.preventAndStop(event);

      this.dropCallback(event);
      this.detectChanges();
    }
    this.scheduleActionService?.isDragging.next(null);
    this.element.classList.remove(this.config.onDragDeniedClass);
  }

  private dragStart(event: Event): void {
    if (this.dragEnabled) {
      this.dragDropService.allowedDropZones = this.dropZones;
      this.dragStartCallback(event);
    }
  }

  private dragEnd(event: Event): void {
    this.dragDropService.allowedDropZones = [];
    this.dragEndCallback(event);
  }

  public isDropAllowed(): boolean {
    if (this.dragDropService.isDragged && this.dropEnabled) {
      const dropzoneData = this.dragDropService.getDataSet();
      if (dropzoneData) {
        switch (dropzoneData.dropZoneType) {
          case DragType.SCHEDULE:
            return this.scheduleActionService.allowDropForScheduleRow()({
              dragData: this.dragDropService.dragData,
              dropZoneData: dropzoneData,
            });
        }
      }

      if (this.dropZones.length === 0 && this.dragDropService.allowedDropZones.length === 0) {
        return true;
      }

      for (const dropZone of this.dragDropService.allowedDropZones) {
        if (this.dropZones.indexOf(dropZone) !== -1) {
          return true;
        }
      }
    }

    return false;
  }

  /**
   * Prevent the given events default action from being called and stops it from being propagated further.
   *
   * @memberof AbstractDirective
   */
  private preventAndStop(event: Event): void {
    if (event.preventDefault) {
      event.preventDefault();
    }

    if (event.stopPropagation) {
      event.stopPropagation();
    }
  }

  public dragEnterCallback(event: Event) {
    console.error('The abstract implementation should not be called');
  }
  public dragOverCallback(event: Event) {
    console.error('The abstract implementation should not be called');
  }
  public dragLeaveCallback(event: Event) {
    console.error('The abstract implementation should not be called');
  }
  public dropCallback(event: Event) {
    console.error('The abstract implementation should not be called');
  }
  public dragStartCallback(event: Event) {
    console.error('The abstract implementation should not be called');
  }
  public dragEndCallback(event: Event) {
    console.error('The abstract implementation should not be called');
  }
  public ngOnDestroy() {
    this.dataSubs.unsubscribe();
  }
}
